import React, { useEffect, useState } from 'react';
import styles from '../../styles/footer/footer.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import home from '../../images/v1/footer/home.png';
// import bookmark from '../../images/v1/home/bookmark.svg';
// import wallet from '../../images/v1/home/wallet.svg';
// import profile from '../../images/v1/home/profile.png';



function Footer() {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/home/landing') ? setIsActive(false) : setIsActive(false);

    }, [location.pathname])

    return (
        <>
            <div className={styles.footerContainer} onContextMenu={(e) => e.preventDefault()}>
                <ul style={{ display: "flex", flexBasis: "100%", marginLeft: "0", paddingLeft: "0" }}>
                    <li className={styles.footerBtn}>
                        <NavLink to="/saved" className={styles.footerLink}>
                            <svg width="16" height="15" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.82347 9.12312C3.22547 13.4851 8.76447 17.0121 10.2365 17.8851C11.7135 17.0031 17.2925 13.4371 18.6495 9.12712C19.5405 6.34112 18.7135 2.81212 15.4275 1.75312C13.8355 1.24212 11.9785 1.55312 10.6965 2.54512C10.4285 2.75112 10.0565 2.75512 9.78647 2.55112C8.42847 1.53012 6.65447 1.23112 5.03747 1.75312C1.75647 2.81112 0.932468 6.34012 1.82347 9.12312ZM10.2375 19.5011C10.1135 19.5011 9.99047 19.4711 9.87847 19.4101C9.56547 19.2391 2.19247 15.1751 0.395468 9.58112C0.394468 9.58112 0.394468 9.58012 0.394468 9.58012C-0.733532 6.05812 0.522469 1.63212 4.57747 0.325118C6.48147 -0.290882 8.55647 -0.019882 10.2345 1.03912C11.8605 0.0111181 14.0205 -0.272882 15.8865 0.325118C19.9455 1.63412 21.2055 6.05912 20.0785 9.58012C18.3395 15.1101 10.9125 19.2351 10.5975 19.4081C10.4855 19.4701 10.3615 19.5011 10.2375 19.5011Z" fill="white" />
                            </svg>
                            <p className={styles.linkName}>Saved</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to="/rewards-new" className={styles.footerLink}>
                            <svg width="16" height="15" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 10.5V14.5C2 17.8 2 19.45 3.025 20.475C4.05 21.5 5.7 21.5 9 21.5H11C14.3 21.5 15.95 21.5 16.975 20.475C18 19.45 18 17.8 18 14.5V10.5M10 5.357C10 4.33406 9.59364 3.35302 8.87031 2.62969C8.14698 1.90636 7.16594 1.5 6.143 1.5H5.786C4.8 1.5 4 2.299 4 3.286C4 4.13841 4.33862 4.9559 4.94136 5.55864C5.5441 6.16138 6.36159 6.5 7.214 6.5H10M10 5.357V6.5M10 5.357C10 4.33406 10.4064 3.35302 11.1297 2.62969C11.853 1.90636 12.8341 1.5 13.857 1.5H14.214C15.2 1.5 16 2.299 16 3.286C16 3.70807 15.9169 4.126 15.7553 4.51594C15.5938 4.90588 15.3571 5.26019 15.0586 5.55864C14.7602 5.85709 14.4059 6.09383 14.0159 6.25535C13.626 6.41687 13.2081 6.5 12.786 6.5H10M10 10.5V21.5M1 8.5C1 7.752 1 7.378 1.201 7.1C1.34356 6.90899 1.53253 6.75754 1.75 6.66C2.098 6.5 2.565 6.5 3.5 6.5H16.5C17.435 6.5 17.902 6.5 18.25 6.66C18.478 6.766 18.667 6.918 18.799 7.1C19 7.378 19 7.752 19 8.5C19 9.248 19 9.621 18.799 9.9C18.6564 10.091 18.4675 10.2425 18.25 10.34C17.902 10.5 17.435 10.5 16.5 10.5H3.5C2.565 10.5 2.098 10.5 1.75 10.34C1.53253 10.2425 1.34356 10.091 1.201 9.9C1 9.621 1 9.248 1 8.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <p className={styles.linkName}>Rewards</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to='/home/landing' className={isActive ? '' : ''}>
                            <img src={home} style={{background: "#FFE016", padding: "2px", borderRadius:"65%", width:"28px", height:"28px", marginBottom:".3em"}} />
                            <p className={styles.linkName} >Home</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to="/leaderboard" className={styles.footerLink}>
                            <svg width="16" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8267 19H6.82666M12.8267 19V10.6C12.8267 10.4409 12.7634 10.2883 12.6509 10.1758C12.5384 10.0632 12.3858 10 12.2267 10H7.42666C7.26753 10 7.11492 10.0632 7.0024 10.1758C6.88987 10.2883 6.82666 10.4409 6.82666 10.6V19M12.8267 19H18.2267C18.3858 19 18.5384 18.9368 18.6509 18.8243C18.7634 18.7118 18.8267 18.5591 18.8267 18.4V16.1C18.8267 15.9409 18.7634 15.7883 18.6509 15.6758C18.5384 15.5632 18.3858 15.5 18.2267 15.5H13.4267C13.2675 15.5 13.1149 15.5632 13.0024 15.6758C12.8899 15.7883 12.8267 15.9409 12.8267 16.1V19ZM6.82666 19V14.1C6.82666 13.9409 6.76345 13.7883 6.65092 13.6758C6.5384 13.5632 6.38579 13.5 6.22666 13.5H1.42666C1.26753 13.5 1.11492 13.5632 1.0024 13.6758C0.889874 13.7883 0.82666 13.9409 0.82666 14.1V18.4C0.82666 18.5591 0.889874 18.7118 1.0024 18.8243C1.11492 18.9368 1.26753 19 1.42666 19H6.82666ZM8.63266 3.11302L9.54166 1.18602C9.5662 1.13093 9.60618 1.08413 9.65676 1.05129C9.70734 1.01845 9.76635 1.00098 9.82666 1.00098C9.88697 1.00098 9.94598 1.01845 9.99656 1.05129C10.0471 1.08413 10.0871 1.13093 10.1117 1.18602L11.0217 3.11302L13.0537 3.42402C13.3147 3.46402 13.4187 3.80002 13.2297 3.99202L11.7597 5.49202L12.1067 7.61002C12.1507 7.88202 11.8787 8.09002 11.6447 7.96102L9.82666 6.96102L8.00866 7.96102C7.77566 8.08902 7.50266 7.88202 7.54666 7.61002L7.89366 5.49202L6.42366 3.99202C6.23366 3.80002 6.33866 3.46402 6.59866 3.42402L8.63266 3.11302Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className={styles.linkName}>Leaderboard</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to="/redeem" className={styles.footerLink}>
                            <svg width="16" height="15" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 5.5H9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M19.833 6.5H17.231C15.446 6.5 14 7.843 14 9.5C14 11.157 15.447 12.5 17.23 12.5H19.833C19.917 12.5 19.958 12.5 19.993 12.498C20.533 12.465 20.963 12.066 20.998 11.565C21 11.533 21 11.494 21 11.417V7.583C21 7.506 21 7.467 20.998 7.435C20.962 6.934 20.533 6.535 19.993 6.502C19.959 6.5 19.917 6.5 19.833 6.5Z" stroke="white" stroke-width="1.5" />
                                <path d="M19.965 6.5C19.887 4.628 19.637 3.48 18.828 2.672C17.657 1.5 15.771 1.5 12 1.5H9C5.229 1.5 3.343 1.5 2.172 2.672C1 3.843 1 5.729 1 9.5C1 13.271 1 15.157 2.172 16.328C3.343 17.5 5.229 17.5 9 17.5H12C15.771 17.5 17.657 17.5 18.828 16.328C19.637 15.52 19.888 14.372 19.965 12.5" stroke="white" stroke-width="1.5" />
                                <path d="M16.991 9.5H17.001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <p className={styles.linkName}>Redeem</p>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default React.memo(Footer);