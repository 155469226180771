import React, { useState, useEffect, useRef } from 'react';
import AvatarBuilder from '../avatar/components/AvatarProperties';
import { AvatarProperties } from '../avatar/components/AvatarProperties';
import HairStyle, { hairColors } from '../avatar/components/top/HairStyle';
import Clothes, { clotheColors } from '../avatar/components/clothes/Clothes';
import FacialHair, { facialHairColors } from '../avatar/components/top/FacialHair';
import Eyes from '../avatar/components/face/Eyes';
import Eyebrow from '../avatar/components/face/Eyebrow';
import Mouth from '../avatar/components/face/Mouth';
import Skin from '../avatar/components/Skin';
import Accessories from '../avatar/components/top/Accessories';
import './AvatarEditor.css'
import { AvatarPreview } from '../avatar/components/AvatarProperties';
import { saveAvatar, loadAvatar } from '../../ApiCalls/Profile/api_v1_profile';

const AvatarEditor = () => {
    const [activeTab, setActiveTab] = useState('FACIAL HAIR');
    const [avatarOptions, setAvatarOptions] = useState([]);
    const [userOptions, setUserOptions] = useState(AvatarProperties.defaultProperties);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const tabsRef = useRef(null);

    const tabs = [
        'FACIAL HAIR',
        'FACIAL HAIR COLOR',
        'HAIRSTYLE',
        'HAIR COLOR',
        'OUTFIT',
        'OUTFIT COLOR',
        'EYES',
        'EYEBROWS',
        'MOUTH',
        'SKIN',
        'ACCESSORIES'
    ];

    useEffect(() => {
        document.title = "Avatar Editor | Ding";
    }, [])

    useEffect(() => {
        if (tabsRef.current) {
            const activeTab = tabsRef.current.querySelector('.avatar-tab-active');
            if (activeTab) {
                activeTab.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        }
    }, [activeTab]);


    useEffect(() => {
        updateAvatarOptions(activeTab);
    }, [activeTab]);

    useEffect(() => {
        const loadSavedOptions = async () => {
            try {
                const response = await loadAvatar();
                if (response && response.data && response.data.avatar) {
                    const avatarData = response.data.avatar;
                    const relevantOptions = {
                        style: avatarData.style || "Transparent",
                        topType: avatarData.topType || "Flat",
                        accessoriesType: avatarData.accessoriesType || "Nothing",
                        hairColor: avatarData.hairColor || "Black",
                        facialHairType: avatarData.facialHairType || "Nothing",
                        facialHairColor: avatarData.facialHairColor || "BlondeGolden",
                        clotheType: avatarData.clotheType || "Crew Neck Tee",
                        clotheColor: avatarData.clotheColor || "Blue01",
                        eyeType: avatarData.eyeType || "Default",
                        eyebrowType: avatarData.eyebrowType || "Default",
                        mouthType: avatarData.mouthType || "Default",
                        skinColor: avatarData.skinColor || "White"
                    };
                    setUserOptions(relevantOptions);
                }
            } catch (error) {
                console.error('Error loading saved avatar options:', error);
            }
        };
        loadSavedOptions();
    }, []);

    const handleSaveAvatar = async () => {
        setIsSaving(true);
        setSaveError(null);
        try {
            localStorage.setItem('avatarOptions', JSON.stringify(userOptions));
            localStorage.setItem('avatarChanged', 'true');

            const response = await saveAvatar(userOptions);
            if (response.data) {
            } else {
                throw new Error('No data received from the server');
            }
        } catch (error) {
            console.error('Error saving avatar:', error);
            setSaveError('Failed to save avatar. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    const updateAvatarOptions = (tab) => {
        let options = [];
        switch (tab) {
            case 'OUTFIT':
                options = AvatarProperties.clotheTypeOptions;
                break;
            case 'HAIRSTYLE':
                options = AvatarProperties.topTypeOptions;
                break;
            case 'FACIAL HAIR':
                options = AvatarProperties.facialHairTypeOptions;
                break;
            case 'EYES':
                options = AvatarProperties.eyeTypeOptions;
                break;
            case 'EYEBROWS':
                options = AvatarProperties.eyebrowTypeOptions;
                break;
            case 'MOUTH':
                options = AvatarProperties.mouthTypeOptions;
                break;
            case 'SKIN':
                options = AvatarProperties.skinColorOptions;
                break;
            case 'ACCESSORIES':
                options = AvatarProperties.accessoriesTypeOptions;
                break;
            case 'HAIR COLOR':
                options = AvatarProperties.hairColorOptions;
                break;
            case 'FACIAL HAIR COLOR':
                options = AvatarProperties.facialHairColorOptions;
                break;
            case 'OUTFIT COLOR':
                options = AvatarProperties.clotheColorOptions;
                break;
            default:
                options = [];
        }
        setAvatarOptions(options);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleOptionClick = (option) => {
        setUserOptions(prev => {
            const key = getKeyForTab(activeTab);
            return { ...prev, [key]: option };
        });
    };

    const getKeyForTab = (tab) => {
        switch (tab) {
            case 'OUTFIT': return 'clotheType';
            case 'HAIRSTYLE': return 'topType';
            case 'FACIAL HAIR': return 'facialHairType';
            case 'EYES': return 'eyeType';
            case 'EYEBROWS': return 'eyebrowType';
            case 'MOUTH': return 'mouthType';
            case 'SKIN': return 'skinColor';
            case 'ACCESSORIES': return 'accessoriesType';
            case 'HAIR COLOR': return 'hairColor';
            case 'FACIAL HAIR COLOR': return 'facialHairColor';
            case 'OUTFIT COLOR': return 'clotheColor';
            default: return '';
        }
    };

    const createRipple = (event) => {
        const button = event.currentTarget;
        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
        circle.classList.add("ripple");

        const ripple = button.getElementsByClassName("ripple")[0];
        if (ripple) {
            ripple.remove();
        }

        button.appendChild(circle);
    };

    return (
        <div className="avatar-editor-layout">
            <div className="avatar-preview-area">
                <div className="avatar-preview-content">
                    <AvatarBuilder properties={userOptions} />
                </div>
                <button
                    className="avatar-save-button"
                    onClick={handleSaveAvatar}
                    disabled={isSaving}
                >
                    {isSaving ? 'Saving...' : 'Save'}
                </button>
                {saveError && <div className="save-error">{saveError}</div>}
            </div>
            <h2 className="avatar-customize-title">Customize according to your choice</h2>
            <div className="avatar-tabs-container" ref={tabsRef}>
                {tabs.map(tab => (
                    <button
                        key={tab}
                        className={`avatar-tab ${activeTab === tab ? 'avatar-tab-active' : ''}`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="avatar-content-area">
                <div className="avatar-options-grid">
                    {avatarOptions.map(option => (
                        <button
                            key={option}
                            className={`avatar-option ${activeTab.includes('COLOR') ? 'color-option' : ''}`}
                            onClick={(e) => {
                                createRipple(e);
                                handleOptionClick(option);
                            }}
                        >
                            <AvatarPreview
                                currentProperties={userOptions}
                                activeTab={activeTab}
                                previewOption={option}
                            />
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default function AvatarEditorStylized() {
    return (
        <>
            <AvatarEditor />
        </>
    );
}